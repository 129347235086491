import { createReducer } from '@reduxjs/toolkit'
import { setFiltered, setCriteria, setAll, clearCriteria, setOnOff } from './actions'

const filters = createReducer({ filtered: false, all: false, onOff: false, criteria: {} }, {
  [setFiltered]: (state, action) => { state.filtered = action.payload },
  [setAll]: (state, action) => { state.all = action.payload },
  [setCriteria]: (state, action) => { state.criteria = { ...state.criteria, ...action.payload } },
  [clearCriteria]: (state) => { state.criteria = {} },
  [setOnOff]: (state, action) => { state.onOff = action.payload }
})

export default filters