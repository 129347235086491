import React from 'react'
import { Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { takeOut } from '../services/store/modules/messager/actions'
import styles from "../assets/styles/messager.module.css"

const Messager = () => {
  const show = useSelector(state => state.messager.show)
  const variant = useSelector(state => state.messager.variant)
  const msg = useSelector(state => state.messager.message)
  const dispatch = useDispatch()
  const classes = [styles.messager, window.location.pathname !== '/login' ? styles.login : '']

  if (show) { setTimeout(() => dispatch(takeOut()), 2000) }

  return (
    <Alert show={show} variant={variant} className={classes}>
      {msg}
    </Alert>

  )

}

export default Messager