import React, { lazy } from "react";
import { getUser } from "../services/auth";
import PrivateRoute from "./PrivateRoute";

const Finances = lazy(() => import("../pages/admin/Finances"));
const TransfersSummary = lazy(() => import('../pages/admin/TransfersSummary'));
const PaymentsSummary = lazy(() => import('../pages/admin/PaymentsSummary'));
const PaymentsList = lazy(() => import('../pages/admin/PaymentsList'));
const TransfersList = lazy(() => import('../pages/admin/TransfersList'));
const TransactionList = lazy(() => import('../pages/admin/TransactionList'));
const PaymentMethods = lazy(() => import('../pages/admin/PaymentMethods'));
const PriceChangesList = lazy(() => import('../pages/admin/PriceChangesList'));
const IssReport = lazy(() => import("../pages/admin/IssReport.jsx"));

const FinanceRoutes = () => {
  const user = JSON.parse(getUser());
  const authFactor = user?.role === 'admin' && [0, 2, 3].includes(user.role_details.role);
  console.log(authFactor);
  return (
    <>
      <PrivateRoute path={'/admin/finances'} auth={authFactor} component={Finances} />
      <PrivateRoute path={'/admin/transferssummary'} auth={authFactor} component={TransfersSummary} />
      <PrivateRoute path={'/admin/paymentssummary'} auth={authFactor} component={PaymentsSummary} />
      <PrivateRoute path={'/admin/paymentslist'} auth={authFactor} component={PaymentsList} />
      <PrivateRoute path={'/admin/transferslist'} auth={authFactor} component={TransfersList} />
      <PrivateRoute path={'/admin/transactionlist'} auth={authFactor} component={TransactionList} />
      <PrivateRoute path={'/admin/paymentmethods'} auth={authFactor} component={PaymentMethods} />
      <PrivateRoute path={'/admin/pricechanges'} auth={authFactor} exact component={PriceChangesList} />
      <PrivateRoute path={'/admin/issreport'} auth={authFactor} exact component={IssReport} />
    </>
  );
};

export default FinanceRoutes;