import jwt from 'jsonwebtoken';
import { jwtSecret } from '../configs/configs';

const tokenKey = '@fretec-token';
const userKey = '@fretec-user';

export const isAuthenticated = () => {
  const atualToken = localStorage.getItem(tokenKey);

  if (atualToken) {
    try {
      jwt.verify(atualToken, jwtSecret);

      return true;
    } catch (err) {
      // console.log(jwtSecret, err)
      logout();
      return false;
    }
  }
};

export const login = (token, user) => {
  localStorage.setItem(tokenKey, token);
  localStorage.setItem(userKey, user);
};

export const logout = () => {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(userKey);
  window.location.href = '/';
};

export const getToken = () => localStorage.getItem(tokenKey);

export const getUser = () => localStorage.getItem(userKey);