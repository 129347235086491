import React from 'react'
import { Spinner, Modal } from 'react-bootstrap'
import { usePromiseTracker } from 'react-promise-tracker'

const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker()

  return (
    <Modal className="loading-modal" dialogClassName='min-dialog' backdrop='static' show={promiseInProgress} centered>
      <Spinner
        as='span'
        animation="border"
        variant=''
        className='blue fixed-loading'
      />
    </Modal>
  )
}

export default LoadingIndicator