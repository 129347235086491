import { trackPromise } from "react-promise-tracker";
import api from "./api";
import { getUser } from "./auth";
import { maskNumber } from "./masks";

const treatData = (type, data) => {
  const dataTypes = {
    admin: {
      ...data,
      blocked: false,
      cpf: maskNumber(data?.cpf),
      phone: maskNumber(data?.phone),
      adminRole: parseInt(data?.adminRole),
      role: 'admin'
    },
    driver: {
      ...data,
      blocked: false,
      cpf: maskNumber(data?.cpf),
      phone: maskNumber(data?.phone),
      model: parseInt(data?.model),
      address: {
        ...data?.address,
        number: data.address?.number ?? 'S/N'
      },
      role: 'driver',
    },
    company: {
      ...data,
      blocked: false,
      cnpj: maskNumber(data?.cnpj),
      phone: maskNumber(data?.phone),
      address: {
        ...data?.address,
        number: data.address?.number ?? 'S/N'
      },
      role: 'company',
      segment: data.segment ?? 'Não informado'
    },
    employee: {
      ...data,
      blocked: false,
      cpf: maskNumber(data?.cpf),
      phone: maskNumber(data?.phone),
      cnpj: JSON.parse(getUser())?.cpf_cnpj,
      role: 'employee'
    },
    delivery: data,
    forgotPass: {
      email: data?.email,
      cpf: maskNumber(data?.doc)
    },
    resetPass: data,
    favorite: {
      ...data,
      recipient: {
        name: data.recipient?.name,
        phone: maskNumber(data.recipient?.phone)
      }
    }
  };

  return dataTypes[type];
};

export const create = (type, data) => {
  const urls = {
    admin: '/users',
    driver: '/users',
    company: '/users',
    employee: '/users',
    delivery: '/request',
    forgotPass: '/forgot_password',
    resetPass: '/reset_password',
    favorite: `/favorites/${JSON.parse(getUser())?._id}`
  };

  const adminHeader = () => {
    const user = JSON.parse(getUser());

    return user
      ? user.role = 'admin' || 'company' ? { headers: { "admin": `${user._id}` } } : ''
      : '';
  };

  return api.post(urls[type], treatData(type, data), adminHeader())
    .then(res => (res?.data))
    .catch(err => { throw err.response?.data.error ?? err.response?.data.erro ?? err.response?.data.message; });
};

export const read = (type, payload) => {
  const urls = {
    user: `/users/${payload}`,
    logs: `/getlogs/${payload}`,
    drivers: `/drivers/${payload}`,
    payment: `/payment/${payload}`,
    bank: `/bank/${payload}`,
    online: '/onlines/drivers',
    inRoute: `/location/${payload}`,
    request: `/request/${payload}`,
    payments: '/payments/',
    transfers: `/transfers/?page=${payload?.page}&paid=${payload?.paid}`,
    filteredPayTransfers: payload,
    address: `address/latlong?street=${payload?.street}&number=${payload?.number}&district=${payload?.district}&city=${payload?.city}&uf=${payload?.uf}`,
    driversOnOff: `/drivers/vehicle/${payload?.model}?online=${payload?.on}`,
    favorites: `/favorites/${payload}`,
    documents: `/documents/${payload?.model}/${payload?.id}`,
  };

  return api.get(urls[type])
    .then(res => (res?.data))
    .catch(err => { throw err.response?.data.error || err.response?.data.message });
};

export const update = (type, id, update) => {
  const urls = {
    approveDriver: `/driver/unblock/${id}`,
    block: `/users/update/${id}`,
    unblock: `/users/update/${id}`,
    disapproveDriver: `/driver/block/${id}`,
    pending: `/pending_update/${id}`,
    updatePassword: `/users/update_password/${id}`,
    user: `/users/update/${id}`,
    favorite: `/favorites/${id}`,
    bonus: `/bonus/driver/${id}`,
    addDelivery: `/request/address/${id}`,
    tecnacio: `/request/fred/accept/${id}`,
    shiftDriver: `/request/driver/${id}`,
    addReturn: `request/addreturn/${id}`,
    changeValue: `request/price/${id}`
  };

  return trackPromise(api.put(urls[type], update).catch(err => { throw err.response.data.error; }));
};

export const del = (type, id) => {
  const action = {
    user: api.put(`/users/update/${id}`, { blocked: true }),
    favorite: api.delete(`/favorites/${id}`)
  };

  return trackPromise(action[type].catch(err => { throw err.response.data.error; }));
};

export const readMultiple = (urls) => {
  const responses = urls.filter(Boolean).map(url => api.get(url)
    .then(res => res.data)
    .catch(err => { throw err.response.data.error; })
  );

  return Promise.all(responses);
};

export const cancelRequest = (request) => trackPromise(api.post(`/cancelrequest/${request._id}`, {
  id_user: request.id_user,
  justification: 'Desistência do solicitante.'
})
  .catch(err => { throw err.response.data.error; }));

export const readFiltered = (criteria, paid, page, type) => {
  const { element, startDate, endDate, seeAll } = criteria;
  const initialDate = Date.parse('01/01/2020 GMT-0300');
  const finalDate = Date.parse('01/01/2100 GMT-0300');
  const oneDay = 86400000; //One day in miliseconds
  const initial = startDate ?? initialDate;
  const final = (endDate ?? finalDate) + oneDay;

  const filterUrls = {
    payments: element
      ? seeAll
        ? `/payments/company/${element._id}?initial=${initial}&final=${final}&paid=${paid}`
        : `/pay/company/${element._id}?initial=${initial}&final=${final}&paid=${paid}&page=${page}`
      : `/payments?initial=${initial}&final=${final}&paid=${paid}&page=${page}`,
    transfers: element
      ? seeAll
        ? `/transfers/driver/${element.id_driver}?initial=${initial}&final=${final}&paid=${paid}`
        : `/transfer/driver/${element.id_driver}?initial=${initial}&final=${final}&paid=${paid}&page=${page}`
      : `/transfers?initial=${initial}&final=${final}&paid=${paid}&page=${page}`,
    bonus: element
      ? `/bonus/driver/${element.id_driver}?initial=${initial}&final=${final - oneDay}&paid=${paid}&page=${page}`
      : `/bonus?initial=${initial}&final=${final - oneDay}&paid=${paid}&page=${page}`,
    transfersSummary: `/transfers/list/price?paid=${paid}&initial=${initial}&final=${final}`,
    paymentsSummary: `/payments/list/price?paid=${paid}&initial=${initial}&final=${final}`
  };

  return read('filteredPayTransfers', filterUrls[type]);
};
