import React, { lazy, useCallback, useEffect } from 'react';
import api from '../services/api';
import { getUser } from '../services/auth.js';
import PrivateRoute from './PrivateRoute';

// const CompanyDashboard = lazy(() => import('../pages/company/CompanyDashboard'));
// const CreditDashboard = lazy(() => import('../pages/company/CreditDashboard'));
// const EmployeesList = lazy(() => import('../pages/company/EmployeesList'));
// const DeliveriesList = lazy(() => import('../pages/company/DeliveriesList'));
// const PaymentsList = lazy(() => import('../pages/company/PaymentsList'));
// const FavoritesList = lazy(() => import('../pages/common/FavoritesList'));
// const Help = lazy(() => import("../pages/common/Help"));
// const TransactionList = lazy(() => import("../pages/company/TransactionsList"));
const LegacyPage = lazy(() => import('../pages/common/Legacy.jsx'));


const CompanyRoutes = () => {
  const user = JSON.parse(getUser());
  const authFactor = user?.role === 'company' && user?.role_details.role === 'requester';

  const ping = useCallback(() => {
    const interval = setInterval(() => {
      api.post('/ping/company', { id: user.role_details._id })
        .then()
        .catch((err) => console.log(err));
    }, 60000);

    return () => clearInterval(interval);
  }, [user]);

  useEffect(() => {
    authFactor && ping();
  }, [authFactor, ping]);

  return (
    <>
      <PrivateRoute path={'/company'} auth={authFactor} exact component={LegacyPage} />
      <PrivateRoute path={'/company/*'} auth={authFactor} exact component={LegacyPage} />
      {/* <PrivateRoute path={'/company/credit'} auth={authFactor} exact component={CreditDashboard} />
      <PrivateRoute path={'/company/scheduler'} auth={authFactor} exact component={CompanyDashboard} />
      <PrivateRoute path={'/company/employeeslist'} auth={authFactor} exact component={EmployeesList} />
      <PrivateRoute path={'/company/deliverieslist'} auth={authFactor} exact component={DeliveriesList} />
      <PrivateRoute path={'/company/paymentslist'} auth={authFactor} exact component={PaymentsList} />
      <PrivateRoute path={'/company/favoriteslist'} auth={authFactor} exact component={FavoritesList} />
      <PrivateRoute path={'/company/help'} auth={authFactor} exact component={Help} />
      <PrivateRoute path={'/company/transactions'} auth={authFactor} exact component={TransactionList} /> */}
    </>
  );
};

export default CompanyRoutes;