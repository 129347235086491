import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated } from '../services/auth';

const PrivateRoute = ({ component: Component, ...rest }) => {

  return (
    <Route
      {...rest}
      render={props => isAuthenticated() && rest.auth
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      }
    />
  );
};

export default PrivateRoute;