import crypto from 'crypto';
import { promisify } from 'util';

const algorithm = "aes-256-ctr";
const random = promisify(crypto.randomBytes);

async function getRandom(n) {
  return await random(n).catch((err) => {
    throw Error(err);
  });
}

export async function encrypt(text) {
  const iv = await getRandom(16);
  const cipher = crypto.createCipheriv(algorithm, process.env.REACT_APP_CRYPTO_KEY, iv);
  const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

  return {
    iv: iv.toString("hex"),
    content: encrypted.toString("hex"),
  };
}

export function decrypt(hash) {
  const decipher = crypto.createDecipheriv(
    algorithm,
    process.env.REACT_APP_CRYPTO_KEY,
    Buffer.from(hash.iv, "hex")
  );

  const decrpyted = Buffer.concat([
    decipher.update(Buffer.from(hash.content, "hex")),
    decipher.final(),
  ]);

  return decrpyted.toString();
}