import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import PTBR from './languages/pt/pt-br.json';
import PTPT from './languages/pt/pt-pt.json';

const resources = {
  'pt-PT': PTPT,
  'pt-BR': PTBR
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: navigator.language,
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;