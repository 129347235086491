import React, { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { creditCardSignup, creditCardUpdate } from "../pages/company/credit/CreditModal";
import api from '../services/api';
import { getUser } from '../services/auth';
import { cancelRequest, create, del, update } from '../services/crud';
import { epochConvert } from '../services/dates';
import { pay } from '../services/pay';
import { takeOut } from '../services/store/modules/confirmation/actions';
import { populate } from '../services/store/modules/dataList/actions';
import { errorMessage, successMessage } from '../services/store/modules/messager/actions';
import { setPages } from '../services/store/modules/pagination/actions';
import FooterButtons from './FooterButtons';
import Loading from './Loading';

const Confirmation = () => {
  const [infos, setInfos] = useState();
  const { t } = useTranslation();
  const show = useSelector(state => state.confirmation.show);
  const type = useSelector(state => state.confirmation.type);
  const elem = useSelector(state => state.confirmation.elem);
  const data = useSelector(state => state.dataList[0]);
  const page = useSelector(state => state.pagination.page);
  const model = useSelector(state => state.model);
  const finished = useSelector(state => state.finished);
  const uf = useSelector(state => state.filters.criteria.uf);
  const dispatch = useDispatch();

  const reload = () => {
    const urls = new Map([
      // ['/admin/driverspending', `/drivers/pending?page=${page}&model=${model}`],
      ['/admin/driverspending', `/filteruf?uf=${uf}&model=${model}&page=${page}`],
      ['/admin/deliverieslist', `/allrequest?finished=${finished}&page=${page}`],
    ]);

    const action = urls.get(window.location.pathname);

    action
      ? trackPromise(
        api.get(action)
          .then(res => {
            dispatch(setPages(res.data.pages));
            dispatch(populate(res.data.results));
          })
          .catch(err => dispatch(errorMessage(err.response.data.error)))
      )
      : window.location.reload();

  };

  const resolve = (promise) => promise
    .then(res => {
      dispatch(successMessage());
      setTimeout(() => reload(), 1000);
    })
    .catch(err => {
      dispatch(errorMessage(err?.response?.data?.error ?? err?.message ?? err));
    })
    .finally(() => dispatch(takeOut()));

  const callbacks = {
    approveDriver: () => resolve(update('approveDriver', elem._id)),
    block: () => resolve(update('block', elem._id, { blocked: true })),
    unblock: () => resolve(update('unblock', elem._id, { blocked: false })),
    disapproveDriver: () => resolve(update('disapproveDriver', elem._id)),
    delete: () => resolve(del('user', elem._id)),
    payment: (payments) => {
      const promises = payments.map(payment => pay(JSON.parse(payment), 'payment'));

      resolve(Promise.all(promises));
    },
    transfer: (payments) => {
      const promises = payments.map(payment => pay(JSON.parse(payment), 'transfer'));

      resolve(Promise.all(promises));
    },
    bonus: (payments) => {
      const promises = payments.map(payment => pay(JSON.parse(payment), 'bonus'));

      resolve(Promise.all(promises));
    },
    createAdmin: () => resolve(create('admin', elem)),
    createDriver: () => resolve(create('driver', elem)),
    createCompany: () => resolve(create('company', elem)),
    pendingPositive: () => {
      elem.comments.length > 0
        ?
        resolve(update('pending', elem._id, { resolved: 1, comments: `Resolvido por ${JSON.parse(getUser()).name}(${epochConvert(Date.now())})` }))
        :
        dispatch(errorMessage('É obrigatório colocar comentários na pendência, antes de aprová-la'));
    },
    pendingNegative: () => {
      elem.comments.length > 0
        ?
        resolve(update('pending', elem._id, { resolved: 2, comments: `Resolvido por ${JSON.parse(getUser()).name}(${epochConvert(Date.now())})` }))
        :
        dispatch(errorMessage('É obrigatório colocar comentários na pendência, antes de reprová-la'));
    },
    createEmployee: () => resolve(create('employee', elem)),
    cancelRequest: () => resolve(cancelRequest(elem)),
    updateDriver: () => resolve(update('user', elem.id, elem.data)),
    updateCompany: () => resolve(update("user", elem.id, elem.data)),
    deleteFavorite: () => resolve(del('favorite', elem.id)),
    addBonus: () => resolve(update('bonus', elem.role_details._id, { with_bonus: true })),
    removeBonus: () => resolve(update('bonus', elem.role_details._id, { with_bonus: false })),
    tecnacio: () => resolve(update('tecnacio', elem._id))
      .then(() => {
        window.open(`${window.location.origin}/admin/shortmap`, 'Tecnacio', 'width=300,height=500', 'resizable=no');
        localStorage.setItem('tecnacioDelivery', JSON.stringify(elem));
      }),
    shiftDriver: () => resolve(update('shiftDriver', elem.id, { id_driver: elem.driver?.id_driver })),
    createCard: () => resolve(creditCardSignup(elem)),
    updateCard: () => resolve(creditCardUpdate(elem)),
    addReturn: () => resolve(update("addReturn", elem._id)),
    changeValue: () => resolve(update("changeValue", elem.id, { ...elem }))
  };

  const texts = {
    approveDriver: 'Deseja aprovar o entregador ',
    block: 'Deseja bloquear ',
    unblock: 'Deseja desbloquear ',
    disapproveDriver: 'Deseja reprovar o entregador ',
    delete: 'Deseja apagar ',
    payment: 'Deseja confirmar a baixa deste(s) recebimento(s)',
    transfer: 'Deseja confirmar a baixa deste(s) pagamento(s)',
    bonus: 'Deseja confirmar a baixa deste(s) bônus',
    createAdmin: 'Deseja cadastrar o administror ',
    createDriver: 'Deseja cadastrar o entregador ',
    createCompany: 'Deseja cadastrar a empresa ',
    pendingPositive: 'Deseja confirmar a pendência ',
    pendingNegative: 'Deseja negativar a pendência ',
    createEmployee: 'Deseja cadastrar o colaborador ',
    cancelRequest: `Deseja cancelar a rota ${elem.index}`,
    updateDriver: 'Deseja atualizar o entregador ',
    updateCompany: `Deseja atualizar a empresa  ${elem.data?.name}`,
    deleteFavorite: `Deseja deletar o favorito ${elem.recipient?.name}`,
    addBonus: 'Deseja adcionar bônus ao motorista ',
    removeBonus: 'Deseja remover bônus do motorista ',
    tecnacio: `Aceitar a rota nº ${elem.index}`,
    shiftDriver: `Trocar a entrega para ${elem.driver?.name}`,
    createCard: `Cadastrar cartão final ${elem.cardNumber?.slice(-4)}`,
    updateCard: `Atualizar cartão final ${elem.data?.cardNumber?.slice(-4)}`,
    addReturn: `Adicionar retorno a rota nº ${elem.index}`,
    changeValue: `Alterar o valor da rota nº ${elem.index} para ${elem.price?.toFixed(2).replace('.', ',')}`
  };

  useEffect(() => {
    if (Array.isArray(elem)) {
      const accumulate = [];

      elem.forEach(item => {
        const req = data.find(chunk => chunk._id === JSON.parse(item).id);
        accumulate.push({ index: req.index_request, price: req.price });
      });

      setInfos(accumulate.reverse());
    }
  }, [data, elem]);

  return (
    <Modal show={show} dialogClassName='alert-confirm' centered>
      <Modal.Header>
        <Modal.Title>
          {texts[type]}
          {!Array.isArray(elem) && elem?.name}&nbsp;?
        </Modal.Title>
      </Modal.Header>
      {
        Array.isArray(elem) &&
        <Modal.Body>
          {
            infos
              ?
              <Container>
                <Row >
                  {
                    infos.map((info, idx) => (
                      <Col key={idx} xs={4} style={{ marginBottom: '5px' }}>
                        <strong>{type === 'bonus' ? 'Bônus' : 'Rota nº'}&nbsp;</strong>{info.index}&nbsp;-&nbsp;
                        <strong>Valor:</strong>&nbsp;{t('$')}&nbsp;{info.price.toFixed(2).replace('.', ',')}
                      </Col>
                    ))
                  }
                </Row>
              </Container>
              :
              <Loading />
          }
        </Modal.Body>
      }
      <Modal.Footer>
        <FooterButtons
          type='confirmation'
          cbCancel={() => dispatch(takeOut())}
          cbConfirm={() => callbacks[type](elem)}
        />
      </Modal.Footer>
    </Modal >

  );
};

export default Confirmation;