import React from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'

const FooterButtons = (props) => {
  const model = useSelector(state => state.model)

  const confirmTexts = {
    delete: 'Deletar',
    signup: 'Cadastrar',
    confirm: 'Confirmar',
    payment: 'Baixar',
    transfer: 'Pagar',
    confirmation: 'OK',
    bonus: 'Pagar'
  }

  return (
    <Row style={{ width: "100%" }}>
      <Col className='groupButtons' >
        {props.type && <Button variant='' className='secondaryButton' style={{marginBottom: '0px'}} onClick={() => props.cbCancel()}>Sair</Button>}
        {
          model !== "Pagos" &&
          < Button
            disabled={['payment', 'transfer'].includes(props.type) && props.payload.length === 0}
            variant=''
            className='primaryButton'
            onClick={(e) => props.cbConfirm(e)}
            style={{marginBottom: '0px'}}
          >
            {confirmTexts[props.type] ?? 'Sair'}
          </Button>
        }
      </Col>
    </Row >
  )
}

export default FooterButtons