import { createReducer } from "@reduxjs/toolkit"
import { setOnlines, setAvailable, setInRoute } from "./actions"

const onlines = createReducer({ all: [], available: [], inRoute: [] }, {
  [setOnlines]: (state, action) => {
    state.all = action.payload
  },
  [setAvailable]: (state, action) => {    
    state.available = action.payload
  },
  [setInRoute]: (state, action) => {
    state.inRoute = action.payload
  }
})

export default onlines