import React from "react";
import { Provider } from "react-redux";
import Confirmation from "./components/Confirmation";
import Messager from "./components/Messager";
import Routes from "./routes/Routes";
import store from "./services/store/store";
// import MaintenancePage from './pages/common/Maintenance'

const App = () => {
  return (
    <Provider store={store}>
      <Messager />
      <Confirmation />
      <Routes />
    </Provider>
  );
};

// const App = () => (<MaintenancePage />);

// const App = () => (<LegacyPage />);

export default App;
