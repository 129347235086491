import axios from 'axios';
import { URL } from '../configs/configs';
import { getToken, getUser, logout } from './auth';

const api = axios.create({ 
  baseURL: URL,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin' : '*',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  }
});

api.interceptors.request.use(config => {
  const token = getToken();
  const user = JSON.parse(getUser());

  config.headers['Content-Type'] = 'application/json';

  if (token) {
    config.headers['role'] = user.role_details.role;
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) { logout(); }

    return Promise.reject(error);
  }
);

export default api;
