import { Form } from '@unform/web';
import React, { useEffect, useRef } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import { withRouter } from 'react-router-dom';
import { ValidationError } from "yup";
import DateInput from '../../../components/DateInput';
import Input from '../../../components/Input';
import apiPay from '../../../services/apiPay';
import { getUser } from "../../../services/auth";
import { encrypt } from '../../../services/crypto';
import { onlyNumbers } from "../../../services/masks";
import { confirm } from "../../../services/store/modules/confirmation/actions";
import { errorMessage, successMessage } from "../../../services/store/modules/messager/actions";
import schema from '../../../services/validators/formCardValidator';

const parseDate = (date) => {
  const months = new Map([
    ["01", "January"],
    ["02", "February"],
    ["03", "March"],
    ["04", "April"],
    ["05", "May"],
    ["06", "June"],
    ["07", "July"],
    ["08", "August"],
    ["09", "September"],
    ["10", "October"],
    ["11", "November"],
    ["12", "December"]
  ]);

  const [month, year] = date.split(/(\d{2})/).filter(elem => !!elem);

  return new Date(`01 ${months.get(month)} 20${year}`);

};

const encryptData = (data) => {
  return encrypt(JSON.stringify({
    number: onlyNumbers(data.cardNumber).join(''),
    cvv: data.cvv,
    expiration: `${(data.validity.getMonth() + 1).toString().padStart(2, '0')}${data.validity.getFullYear().toString().slice(-2)}`,
    holder: data.holderName
  }));
};

export const creditCardSignup = async (data) => {
  const user = JSON.parse(getUser());
  const clientID = user.role_details.company?._id ?? user.role_details._id;
  const encryptedData = await encryptData(data);

  return apiPay.post('/payment-method/create', { clientID, data: encryptedData });
};

export const creditCardUpdate = async (elem) => {
  const encryptedData = await encryptData(elem.data);

  return apiPay.patch(`/payment-method/update/${elem.id}`, { data: encryptedData });
};

const CreditModal = (props) => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const handleSubmit = (data) => {
    schema
      .validate(data, { abortEarly: false })
      .then(() => {
        dispatch(confirm({
          type: props.edit ? 'updateCard' : 'createCard',
          elem: props.edit ? { data, id: props.edit._id } : data
        }));
        props.handleClose();
      })
      .catch(err => {
        if (err instanceof ValidationError) {
          const validationErrors = {};

          err.inner.forEach(error => validationErrors[error.path] = error.message);
          formRef.current.setErrors(validationErrors);
        }
        else {
          dispatch(errorMessage(err));
        }
      });
  };

  const creditCardDelete = (id) => trackPromise(apiPay.delete(`/payment-method/delete/${id}`))
    .then(() => {
      dispatch(successMessage());
      setTimeout(() => window.location.reload(), 2000);
    })
    .catch(err => dispatch(errorMessage(err.message)));

  useEffect(() => {
    if (!!props.edit) {
      const data = props.edit.data;
      formRef.current.setData({
        cardNumber: data.number,
        holderName: data.holder,
        cvv: data.cvv
      });
    }
  }, [props.edit]);


  return (
    <>

      {!!props.deleteCard ?

        (

          <Modal show={props.show} onHide={props.handleClose} centered >
            <Modal.Header>
              <Modal.Title style={{ width: '100%', textAlign: 'center' }}>
                Você tem certeza de que deseja excluir o cartão final {props.deleteCard.data.number?.slice(-4)}?
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                <Button variant='' className='secondaryButton' onClick={props.handleClose}>
                  Não
                </Button>
                <Button variant='' className='primaryButton' onClick={() => creditCardDelete(props.deleteCard?._id)}>
                  Sim
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

        )

        :

        (

          <Modal show={props.show} onHide={props.handleClose} backdrop="static" centered>
            <Modal.Header closeButton>
              <Modal.Title style={{ width: '100%', textAlign: 'center' }}>
                {props.edit ? 'Editar ' : 'Cadastrar'} cartão de crédito
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form id='cardForm' ref={formRef} onSubmit={handleSubmit}>
                <Row>
                  <Col xs={10}>
                    <Input
                      type='number'
                      name='cardNumber'
                      placeholder='Número do cartão'
                    />
                  </Col>
                  <Col xs={2}></Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      name='holderName'
                      placeholder='Nome do títular'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <DateInput
                      name='validity'
                      placeholder='Validade'
                      dateFormat='MM/yy'
                      defaultValue={props.edit && parseDate(props.edit.data?.expiration)}
                      required={true}
                    />
                  </Col>
                  <Col>
                    <Input
                      type='number'
                      name='cvv'
                      placeholder='CVV'
                      limit={4}
                    />
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer className='groupButtons'>
              <Button className='secondaryButton' onClick={props.handleClose}>
                Cancelar
              </Button>
              <Button
                type='submit'
                form='cardForm'
                className='primaryButton'
              >
                Salvar
              </Button>
            </Modal.Footer>
          </Modal>

        )

      }

    </>


  );
};

export default withRouter(CreditModal);