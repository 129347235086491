import { combineReducers } from 'redux';
import confirmation from './confirmation/reducer';
import credit from './credit/reducer';
import dataList from './dataList/reducer';
import delivery from './delivery/reducer';
import filters from './filters/reducer';
import finalizedDelivery from './finalizedDelivery/reducer';
import finished from './finished/reducer';
import messager from './messager/reducer';
import model from './model/reducer';
import onlines from './onlines/reducer';
import pagination from './pagination/reducer';
import payments from './payment/reducer';
import period from './period/reducer';

export default combineReducers({
  messager,
  dataList,
  pagination,
  model,
  confirmation,
  onlines,
  payments,
  delivery,
  finished,
  filters,
  finalizedDelivery,
  credit,
  period
});