import React, { lazy } from 'react';
import { getUser } from '../services/auth.js';
import PrivateRoute from './PrivateRoute';

// const EmployeeDashboard = lazy(() => import('../pages/employee/EmployeeDashboard'));
// const DeliveriesList = lazy(() => import('../pages/employee/DeliveriesList'));
// const FavoritesList = lazy(() => import("../pages/common/FavoritesList"));
// const Help = lazy(() => import("../pages/common/Help"));
const LegacyPage = lazy(() => import('../pages/common/Legacy.jsx'));

const EmployeeRoutes = () => {
  const user = JSON.parse(getUser());
  const authFactor = user?.role === 'employee' && user?.role_details.company?.role === 'requester';

  return (
    <>
      <PrivateRoute path={'/employee'} auth={authFactor} exact component={LegacyPage} />
      <PrivateRoute path={'/employee/*'} auth={authFactor} exact component={LegacyPage} />
      {/* <PrivateRoute path={'/employee/deliverieslist'} auth={authFactor} exact component={DeliveriesList} /> */}
      {/* <PrivateRoute path={'/employee/favoriteslist'} auth={authFactor} exact component={FavoritesList} /> */}
      {/* <PrivateRoute path={'/employee/help'} auth={authFactor} exact component={Help} /> */}
    </>
  );
};

export default EmployeeRoutes;