import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loading = () => {
  return (
    <div className='centered' style={{ padding: '20px 0' }}>
      <Spinner animation='border' />
    </div>
  )
}

export default Loading