import StringMask from 'string-mask';

export const onlyNumbers = (text) => text.split('').filter(letter => letter.match(/\d/));

export const onlyLower = (text) => text.toLowerCase();

export const onlyText = (text) => {
  let validText = [...text].filter(carac => carac.match(/^(ç|Ç|\.|[0-9]|[a-zA-Zà-úÀ-Ú]|\s+)+$/));

  return validText.join('');
};

export const maskTel = (text) => {
  if (!text) { return ''; }

  const masks = new Map([
    ['pt-BR', '(00) 90000-0000'],
    ['pt-PT', '000 000 000']
  ]);

  const numbers = onlyNumbers(text.toString()).join('');
  const formatter = new StringMask(masks.get(navigator.language) ?? '99999999999');

  return formatter.apply(numbers);
};

export function maskCnh(text) {
  let numbers = onlyNumbers(text);

  return numbers.join('').slice(0, 11);
};

export function maskCpf(text) {
  if (!text) {
    return '';
  }

  const masks = new Map([
    ['pt-BR', '000.000.000-00'],
    ['pt-PT', '000 000 000']
  ]);

  const formatter = new StringMask(masks.get(navigator.language) ?? '99999999999');
  const numbers = onlyNumbers(text).join('');

  return formatter.apply(numbers);
};

export const maskCnpj = (text) => {
  if (!text) {
    return '';
  }

  const masks = new Map([
    ['pt-BR', '00.000.000/0000-00'],
    ['pt-PT', '000 000 000']
  ]);

  const formatter = new StringMask(masks.get(navigator.language) ?? '99999999999');
  const numbers = onlyNumbers(text).join('');

  return formatter.apply(numbers);
};

export const maskText = (entry) => {
  const text = onlyText(entry);

  const capitalize = (name) => name.slice(0, 1).toUpperCase() + name.slice(1);

  // const abrev = (name) => name.includes('.') ? capitalize(name) : name.toLowerCase()
  const minor = (name) => name.includes('.') || name.charAt(0) !== 'd' ? capitalize(name) : name.toLowerCase();

  const formatedText = text.split(' ').map(elem => elem.length < 4 ? minor(elem) : capitalize(elem)).join(' ');

  return formatedText;
};

export const capitalize = (text) => text.length > 2 ? `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}` : text;

export const maskDate = (date) => date.split('').join('/');

export const maskCep = (cep) => {
  const cepNumbers = onlyNumbers(cep);

  const part1 = cepNumbers.slice(0, 5).join('');
  const part2 = (cepNumbers.length > 5) ? `-${cepNumbers.slice(5, 8).join('')}` : '';

  return `${part1}-${part2}`;
};

export const maskPlate = (plate) => {

  const part1 = [...plate.slice(0, 3).toUpperCase()].filter(char => char.match(/[A-Z]/)).join('');
  const part2 = onlyNumbers(plate.slice(3, 4)).join('');
  const part3 = [...plate.slice(4, 5).toUpperCase()].filter(char => char.match(/[A-Z]|\d/)).join('');
  const part4 = onlyNumbers(plate.slice(5, 7)).join('');

  return `${part1}${part2}${part3}${part4}`.toUpperCase();
};

export const maskBankAccount = (account) => {
  const numbers = [...account].filter(letter => letter.match(/[1-9]/)).join('');
  const zeros = numbers.length < 8 ? "0".repeat(8 - numbers.length) : '';

  return `${zeros}${numbers.slice(0, 8)}`.slice(0, 10);

};

export const maskNumber = (text) => {
  if (!text) { return ''; }

  return onlyNumbers(text).join('');
};

export const maskBankAgency = (agency) => onlyNumbers(agency).join('').slice(0, 4);

export const maskDoc = (doc) => doc.length > 14 ? maskCnpj(doc) : maskCpf(doc);