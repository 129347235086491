import api from "./api"
import { trackPromise } from "react-promise-tracker"
import { getUser } from "./auth"

export const checkAll = (checked) => {
  const checks = Array.from(document.querySelectorAll('.check'))
  checks.map(check => check.checked = checked ? true : false)

  return checks
}

export const pay = (payment, type) => {
  api.defaults.headers.admin = JSON.parse(getUser())._id
  const urls = {
    payment: `/pay/${payment.id}`,
    transfer: `/transfer/${payment.id}`,
    bonus: `/bonus_pay/${payment.id}`
  }

  return trackPromise(api.put(urls[type])
    .then(res => res.data)
    .catch(err => { throw err.response.data.error }))
} 