import { createReducer } from '@reduxjs/toolkit'
import { confirm, takeOut } from './actions'

const confirmation = createReducer({ show: false, type: '', elem: {} }, {
  [confirm]: (state, action) => {
    state.type = action.payload.type
    state.elem = action.payload.elem
    state.show = true
  },
  [takeOut]: () => ({ show: false, type: '', elem: {} })
})

export default confirmation