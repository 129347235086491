import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import "../src/assets/styles/basics.css";
import "../src/assets/styles/hacks.css";
import "../src/assets/styles/styles.css";
import App from "./App.jsx";
import LoadingModal from "./components/LoadingModal";
import './i18n';

const rootElement = document.querySelector("#root");

ReactDOM.render(
  <>
    <App />
    <LoadingModal />
  </>,
  rootElement
);
