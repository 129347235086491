import { createReducer } from '@reduxjs/toolkit';
import { errorMessage, successMessage, takeOut } from './actions';

const messager = createReducer({ show: false, variant: '', message: '' }, {
  [errorMessage]: (state, action) => {
    state.message = typeof action.payload === "string" ? action.payload : 'Houve um erro.';
    state.variant = 'danger';
    state.show = true;

  },
  [successMessage]: (state, action) => {
    state.message = action.payload ?? 'Operação realizada com sucesso.';
    state.variant = 'success';
    state.show = true;
  },
  [takeOut]: () => ({ show: false, variant: '', message: '' })
});

export default messager;