import {createReducer} from '@reduxjs/toolkit'
import {populate,clear} from './actions'

const dataList = createReducer([],{
  [populate]: (state,action)=>{
    state.length=0
    state.push(action.payload?.flat()) 
  },
  [clear]: (state)=>{
    state.length = 0
  }
})

export default dataList