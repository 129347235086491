import React, { lazy } from 'react';
import { getUser } from '../services/auth.js';
import PrivateRoute from './PrivateRoute';

const AdminDashboard = lazy(() => import('../pages/admin/AdminDashboard'));
const DriversPending = lazy(() => import('../pages/admin/DriversPending'));
const AdminList = lazy(() => import('../pages/admin/AdminList'));
const CompaniesList = lazy(() => import('../pages/admin/CompaniesList'));
const Notices = lazy(() => import('../pages/admin/Notices'));
const DriversList = lazy(() => import('../pages/admin/DriversList'));
const BonusList = lazy(() => import('../pages/admin/BonusList'));
const CancelsList = lazy(() => import('../pages/admin/CancelsList'));
const Statistics = lazy(() => import('../pages/admin/Statistics'));
const DeliveriesList = lazy(() => import('../pages/admin/DeliveriesList'));
const RegisterDelivery = lazy(() => import('../pages/admin/RegisterDelivery'));
const PendingsList = lazy(() => import('../pages/admin/PendingsList'));
const DriversBlocked = lazy(() => import('../pages/admin/DriversBlocked'));
const Tecnacio = lazy(() => import('../pages/admin/components/Tecnacio'));
const Franchises = lazy(() => import('../pages/admin/Franchises'));

const AdminRoutes = () => {
  const user = JSON.parse(getUser());
  const authFactor = user?.role === 'admin';
  const ownerFactor = [2].includes(user?.role_details.role);
  const manageFactor = [0, 2].includes(user?.role_details.role);

  return (
    <>
      <PrivateRoute path={'/admin'} auth={authFactor} exact component={AdminDashboard} />
      <PrivateRoute path={'/admin/driverspending'} auth={authFactor} exact component={DriversPending} />
      <PrivateRoute path={'/admin/adminlist'} auth={authFactor && manageFactor} exact component={AdminList} />
      <PrivateRoute path={'/admin/companieslist'} auth={authFactor} exact component={CompaniesList} />
      <PrivateRoute path={'/admin/notices'} auth={authFactor} exact component={Notices} />
      <PrivateRoute path={'/admin/driverslist'} auth={authFactor} exact component={DriversList} />
      <PrivateRoute path={'/admin/driversblocked'} auth={authFactor} exact component={DriversBlocked} />
      <PrivateRoute path={'/admin/bonuslist'} auth={authFactor} exact component={BonusList} />
      <PrivateRoute path={'/admin/cancelslist'} auth={authFactor} exact component={CancelsList} />
      <PrivateRoute path={'/admin/statistics'} auth={authFactor} exact component={Statistics} />
      <PrivateRoute path={'/admin/deliverieslist'} auth={authFactor} exact component={DeliveriesList} />
      <PrivateRoute path={'/admin/registerdelivery'} auth={authFactor} exact component={RegisterDelivery} />
      <PrivateRoute path={'/admin/pendingslist'} auth={authFactor} exact component={PendingsList} />
      <PrivateRoute path={'/admin/shortmap'} auth={authFactor} exact component={Tecnacio} />
      <PrivateRoute path={'/admin/franchises'} auth={authFactor && ownerFactor} exact component={Franchises} />
    </>
  );
};

export default AdminRoutes;