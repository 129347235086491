/* eslint-disable no-undef */
export const jwtSecret = process.env.REACT_APP_JWT_SECRET;
export const payToken = process.env.REACT_APP_PAY_TOKEN;

export const locals = [
  "Natal-RN",
  "Teresina-PI",
  "Lisboa-PT"
];

const apis = new Map([
  ["Natal-RN", process.env.REACT_APP_API_URL],
  ["Teresina-PI", process.env.REACT_APP_API_PI_URL],
  ["Lisboa-PT", process.env.REACT_APP_API_PT_URL]
]);

const apisPay = new Map([
  ["Natal-RN", process.env.REACT_APP_API_PAY_URL],
  ["Teresina-PI", process.env.REACT_APP_API_PI_PAY_URL],
  ["Lisboa-PT", process.env.REACT_APP_API_PT_PAY_URL]
]);


const local = localStorage.getItem("@fretec-local");

export const URL = apis.get(local) ?? process.env.REACT_APP_API_URL;

export const PAY_URL = apisPay.get(local) ?? process.env.REACT_APP_PAY_URL;
