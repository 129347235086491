import { createReducer } from "@reduxjs/toolkit";
import { setPeriod } from "./actions";

const period = createReducer('', {
  [setPeriod]: (state, action) => {
    return action.payload ? action.payload : state;
  }

});


export default period;