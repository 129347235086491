import axios from 'axios';
import { payToken, PAY_URL } from '../configs/configs';
import { getUser } from "./auth";
import { cnpj } from 'cpf-cnpj-validator';
import {onlyNumbers} from '../services/masks'

const apiPay = axios.create({ baseURL: PAY_URL });

apiPay.interceptors.request.use(config => {
  const token = payToken;

  config.headers['Content-Type'] = 'application/json';
  if (token) { config.headers['Authorization'] = `Bearer ${token}`; }

  return config;
});

export function getPayData(total, value, paymentMethod, cardHash) {
  const user = JSON.parse(getUser());
  const documentNumber = user.role === 'company' ? user.cpf_cnpj : user.role_details.company?.cpf_cnpj;

  const data = {
    payment: {
      total,
      value,
      paymentMethod,
      cardHash
    },
    customer: {
      id: user.role === 'company' ? user.role_details._id : user.role_details.company?._id,
      name: user.role === 'company' ? user.name : user.role_details.company?.name,
      email: user.email,
      document: {
        type: cnpj.isValid(documentNumber) ? 'cnpj' : 'cpf',
        number: documentNumber
      },
      "address": {
        state: user.role_details.address.uf.toLowerCase(),
        city: user.role_details.address.city,
        neighborhood: user.role_details.address.district,
        street: user.role_details.address.street,
        street_number: user.role_details.address.number,
        zipcode: user.role_details.address.cep ? [...user.role_details.address.cep.matchAll(/\d/g)].join('') : '59078970'
      },
      phone: onlyNumbers(user.phone).join('')
    }
  };

  return data;
}



export default apiPay;
