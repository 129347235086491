import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Input = (props) => {
  const { name, mask, limit, ...rest } = props;
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const inputRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField, mask]);

  return (
    <FormGroup>
      <FormControl
        ref={inputRef}
        isInvalid={error}
        defaultValue={defaultValue}
        onBlur={() => mask ? inputRef.current.value = mask(inputRef.current.value) : inputRef.current.value}
        onChange={() => name === "email" ? inputRef.current.value = inputRef.current.value.toLowerCase() : inputRef.current.value = inputRef.current.value.slice(0, limit)}
        {...rest}
      />
      <FormControl.Feedback type='invalid'>{t(error)}</FormControl.Feedback>
    </FormGroup>
  );
};

export default Input;