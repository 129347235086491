import { useField } from '@unform/core';
import br from 'date-fns/locale/pt-BR';
import React, { useEffect, useRef, useState } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateInput = (props) => {
  const { name, placeholder, defaultValue } = props;
  const { fieldName, registerField, error } = useField(name);
  const [date, setDate] = useState(defaultValue && new Date(props.defaultValue));
  const datepickerRef = useRef(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: "props.value",
      clearValue: (ref) => {
        ref.clear();
      }
    });

  }, [fieldName, registerField]);

  return (
    <FormGroup>
      <DatePicker
        ref={datepickerRef}
        placeholderText={placeholder}
        className='form-control'
        selected={date}
        value={date}
        locale={br}
        dateFormat={props.dateFormat ?? 'dd/MM/yyyy'}
        isInvalid={error}
        onChange={setDate}
        required={props.required}
      />
      <FormControl.Feedback type='invalid'>{error}</FormControl.Feedback>
    </FormGroup>
  );
};
export default DateInput;
