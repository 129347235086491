import { createReducer } from "@reduxjs/toolkit"
import { addPayment, removePayment, addAll, clearPayments } from "./actions"

const payments = createReducer([], {
  [addPayment]: (state, action) => { state.push(action.payload) },
  [removePayment]: (state, action) => {
    const idx = state.findIndex(pay => JSON.parse(pay).id === JSON.parse(action.payload).id)
    state.splice(idx, 1)
  },
  [addAll]: (_, action) => action.payload,
  [clearPayments]: state => { state.splice(0) },
})

export default payments